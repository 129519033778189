import React from 'react';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
} from 'reactstrap';
import Logo from "../../assets/Logo.png";
import VishwaLogo from "../../assets/VishwaLogo.png";
import HOME from "../../assets/Home.svg";
import styles from "./styles.module.css";

export default function Header(props) {
    return (
        <Navbar className={styles["header-section-1"]}>
            <div id="menu-links" className='container-fluid nav-content d-flex justify-content-between w-100'>
                <div>
                    <NavbarBrand href="/" data-scrollspy="#home"><img className='brand-logo' src={Logo} alt="goGLocal" width={163} height={32} /></NavbarBrand>
                    <img src={VishwaLogo} height={25} width={51} alt="vishwa" />
                </div>
                <Nav className="d-flex flex-row ml-auto justify-content-center align-items-center" navbar>
                    <NavItem>
                        <a href="/" data-scrollspy="#home" className={`link`}>
                            <img alt="." src={HOME} />
                        </a>
                    </NavItem>
                </Nav>
            </div>
        </Navbar>
    )
}
