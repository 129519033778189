import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import ARROW_RIGHT from "../../assets/arrow-right(1) 1.svg";

export default function EmailFieldForm({ initial, setInitial, onSubmit, status, message, ...props }) {
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (initial && status === "success" && message) {
            setEmail("");
            alert(message);
            setInitial(false);
        };
    }, [status, message, initial]);

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    return (
        <div className='email-text-box'>
            <input value={email} type='email' required placeholder='Enter your email here' onChange={e => setEmail(e.target.value)} />
            <Button disabled={Boolean(!validateEmail(email) || status === "sending")} onClick={() => {
                setInitial(true)
                onSubmit({ EMAIL: email })
            }}>
                <img src={ARROW_RIGHT} alt="->" />
            </Button>
        </div>
    )
};