import React from 'react'
import IMG1 from "../../assets/signup.svg";
import IMG2 from "../../assets/globalreadines.svg";
import IMG3 from "../../assets/Digital marketing strategy.svg";
import IMG4 from "../../assets/launching rocket.svg";
import IMG5 from "../../assets/Reviews.svg";
import IMG6 from "../../assets/customergrowth.svg";
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap';

const imgs = [
    {
        img: IMG1,
        title: "Sign Up"
    },
    {
        img: IMG2,
        title: "Global Readiness Check"
    },
    {
        img: IMG3,
        title: "Global Expansion Strategy"
    },
    {
        img: IMG4,
        title: "Global Launch"
    },
    {
        img: IMG5,
        title: "Order Review"
    },
    {
        img: IMG6,
        title: "Customer Growth"
    }];


export default function FAQs() {


    return (
        <>
            <section className='scrollspy primary-background h-100 mt-5' id="faqs">
                <div class="container">
                    <div class="row gx-5">
                        <div class="col-12">
                            <p className='title'>Global Expansion Journey with goGlocal</p>
                        </div>
                        <div className='col-12 mt-4'>
                            <div className='position-relative d-none d-md-flex justify-content-between'>
                                <div className='faq-border-divider' />
                                {imgs.map(p => (
                                    <div className='faq-rounded-card d-flex flex-column align-items-center'>
                                        <div>
                                            <img className='img-fluid' src={p.img} alt="." />
                                        </div>
                                        <p className='text-center mt-3 mb-0 faq-rounded-card-title'>{p.title}</p>
                                    </div>
                                ))}
                            </div>
                            <div className='position-relative d-flex d-md-none flex-column justify-content-between' style={{width:"fit-content"}}>
                                <div className='faq-border-divider-verticle' />
                                {imgs.map(p => (
                                    <div className='faq-rounded-card d-flex flex-row align-items-center mb-4 w-100'>
                                        <div className=''>
                                            <img className='img-fluid' src={p.img} alt="." />
                                        </div>
                                        <p className='text-center px-4 mt-0 mb-0 faq-rounded-card-title'>{p.title}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='mt-5'>
                <p className='faq-title'>FAQs</p>
                <div className='d-flex w-100 flex-column flex-md-row'>
                    <UncontrolledAccordion stayOpen className='d-flex flex-column w-100 w-lg-50'>
                        <div className='m-2'>
                            <AccordionItem className='accordion-item'>
                                <AccordionHeader targetId="1">
                                    What is the Vishwasarthi campaign, and what is its primary objective?
                                </AccordionHeader>
                                <AccordionBody accordionId="1">
                                    Vishwasarthi is an initiative aimed at empowering MSMEs for global success, focusing on fair trade and democratizing global selling, with the goal of enhancing their capacity and e-commerce literacy.
                                </AccordionBody>
                            </AccordionItem>
                        </div>
                        <div className='m-2'>
                            <AccordionItem className='accordion-item'>
                                <AccordionHeader targetId="2">
                                    What is the Vishwasarthi MSME Capacity Building Program?
                                </AccordionHeader>
                                <AccordionBody accordionId="2">
                                    The program offers comprehensive training modules to upskill MSMEs in e-commerce, covering aspects like onboarding, assortment planning, demand generation, logistics, digital skills, customer service, and financial management.
                                </AccordionBody>
                            </AccordionItem>
                        </div>
                        <div className='m-2'>
                            <AccordionItem className='accordion-item'>
                                <AccordionHeader targetId="3">
                                    When and where is the district launch of the Vishwasarthi campaign happening in Uttar Pradesh?
                                </AccordionHeader>
                                <AccordionBody accordionId="3">
                                    The district launch will take place in Varanasi on November 17, 2023, and in Bhadohi on November 18, 2023
                                </AccordionBody>
                            </AccordionItem>
                        </div>
                    </UncontrolledAccordion>
                    <UncontrolledAccordion stayOpen className='d-flex flex-column w-100 w-lg-50'>
                        <div className='m-2'>
                            <AccordionItem className='accordion-item'>
                                <AccordionHeader targetId="1">
                                    Who is the Vishwasarthi campaign focused on?
                                </AccordionHeader>
                                <AccordionBody accordionId="1">
                                    The Vishwasarthi campaign is primarily focused on Micro, Small, and Medium Enterprises (MSMEs), which encompass both manufacturers and sellers.
                                </AccordionBody>
                            </AccordionItem>
                        </div>
                        <div className='m-2'>
                            <AccordionItem className='accordion-item'>
                                <AccordionHeader targetId="2">
                                    How can MSMEs participate in the Vishwasarthi campaign, and is there a cost to join?
                                </AccordionHeader>
                                <AccordionBody accordionId="2">
                                    MSMEs can join the campaign by visiting our website and registering. The campaign is committed to being accessible and offers resources at no cost to participants.
                                </AccordionBody>
                            </AccordionItem>
                        </div>
                    </UncontrolledAccordion>
                </div>
            </div>
            
        </>
    )
}
