import React, { useEffect, useState } from 'react';
import Papa from "papaparse";
import moment from "moment";
import { Button, Modal, ModalFooter, ModalBody } from 'reactstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import styles from "../ContactForm/styles.module.css";
import IMG1 from "../../assets/Rectangle1.png";
import IMG2 from "../../assets/Rectangle2.png";
import IMG3 from "../../assets/Rectangle3.png";
import IMG4 from "../../assets/Rectangle4.png";
import IMG5 from "../../assets/Rectangle5.png";
import IMG6 from "../../assets/Rectangle6.png";
import VishwaLogo from "../../assets/VishwaLogo.png";
import "./index.css";
import ICO1 from "../../assets/globalicon.svg";
import ICO2 from "../../assets/book.svg";
import ICO3 from "../../assets/shop.svg";
import ICO4 from "../../assets/hand.svg";
import ICO5 from "../../assets/verification.svg";
import PARTNERICO1 from "../../assets/make in india.svg";
import PARTNERICO2 from "../../assets/skill india.svg";
import PARTNERICO3 from "../../assets/fieo.svg";
import PARTNERICO4 from "../../assets/up govt.svg";
// import QR from '../../assets/QR Code.svg';
import LOC from '../../assets/location.svg';
import VERIFIED from '../../assets/Verified Account.svg';
import LAUNCH from '../../assets/Launch.svg';
import GLOBAL from '../../assets/Global Finance.svg';
import TESLA from '../../assets/Tesla Supercharger Pin.svg';
import MPI1 from '../../assets/amazon 1.svg';
import MPI2 from '../../assets/ebay 1.svg';
import MPI3 from '../../assets/Walmart Logo 1.svg';
import MPI4 from '../../assets/etsy 1.svg';
import MPI5 from '../../assets/rakuten 1.svg';
import MPI6 from '../../assets/lazada 1.svg';
import MPI7 from '../../assets/mercado libre 1.svg';
import MPI8 from '../../assets/shopee 1.svg';
import Business from '../../assets/Business.svg';
import OnlineStore from '../../assets/Online Store.svg';
import EarthGlobe from '../../assets/Earth Globe.svg';
import Letter from '../../assets/Letter.svg';

const imgs = [IMG1, IMG2, IMG3, IMG4, IMG5, IMG6];
const icons = [
    { title: "Global E-commerce Skill Development", img: ICO1 },
    { title: "Compliance Check and Execution", img: ICO2 },
    { title: "Access to Global Markets", img: ICO3 },
    { title: "Hand Holding and Account Management", img: ICO4 },
    { title: "Demand Generation and Fulfillment", img: ICO5 },
];
const partenrs = [PARTNERICO1, PARTNERICO2, PARTNERICO3, PARTNERICO4];

const marketplacePartenrs = [MPI1, MPI2, MPI3, MPI4, MPI5, MPI6, MPI7, MPI8];

export default function Home() {
    const [configData, setConfigData] = useState([]);
    const [open, setOpen] = useState(false);

    const toggle = () => setOpen(!open);

    useEffect(() => {
        Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vREHrl7_C1l7WqDVZzWVGleOHC5siS_XSzMLrJpIAVkqLKx1UM3dYAhd4dVIaH0A1cVi3gPVmag4Pmw/pub?output=csv", {
            download: true,
            header: true,
            complete: (results) => {
                const today = moment();
                if (results && results.data && results.data.length > 0) {
                    const validEvents = results.data.filter(event => {
                        const eventDate = moment(event.date, 'Do MMMM, YYYY');
                        return today.isSameOrBefore(eventDate);
                    });

                    validEvents.sort((a, b) => {
                        const dateA = moment(a.date, 'Do MMMM, YYYY');
                        const dateB = moment(b.date, 'Do MMMM, YYYY');

                        const diffA = Math.abs(today.diff(dateA, 'days'));
                        const diffB = Math.abs(today.diff(dateB, 'days'));

                        return diffA - diffB; // Sort in ascending order (earliest first)
                    });
                    setConfigData(validEvents);
                }
            }
        });
        Array.from(configData);
    }, []);

    return (
        <>
            <section className='scrollspy primary-background h-100 mt-3' id="home">
                <div class="">
                    <div class="row gx-sm-5">
                        <div class="col-12 col-lg-7">
                            <p className='title mb-0'>Connecting MSMEs to the World</p>
                            <p className='sub-title'>Take your business to new heights globally with Vishwa Sarthi, your ultimate global selling partner.<br /><strong><em>Ab puri duniya khareedegi aapka samaan .</em></strong></p>
                            <div className='mt-lg-5'>
                                <div class="row g-3">
                                    {imgs.map((img, i) => (
                                        <div className="img-preview col-4" key={i}>
                                            <img src={img} alt="img" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-5">
                            <div class="mt-2 mt-lg-0 align-items-center d-flex flex-column">
                                <img src={VishwaLogo} className='vishwa-logo mt-5 mt-lg-2' alt="vishwa" />
                                <p className='title'>Upcoming Events</p>
                            </div>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={12}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                className="event-slider"
                            >
                                {configData.map((ev, i) => (
                                    <SwiperSlide key={i + '_' + ev.eventtag}>
                                        <div className='w-100 h-100 gradient-card p-1'>
                                            <div className='py-2 px-4 d-flex flex-column align-items-center just-fy-content-start'>
                                                <div className='my-3 d-flex gap-2 justify-content-center align-items-center'>
                                                    <img src={LOC} alt="." />
                                                    <p className='card-title p-0 m-0'>{ev.location}</p>
                                                </div>
                                                <div className='qr-divider m-0 mb-3' />
                                                {/* <p className='card-sub-title'>Topic: Coming Soon</p> */}
                                                <div className='mt-4 row align-items-center w-100'>
                                                    <div className='col-9'>
                                                        <p className='qr-content-text qr-content-date-time mb-2'>Date: <span className='qr-content-text'>{ev.date}</span></p>
                                                        <p className='qr-content-text qr-content-date-time mb-2'>Venue: <span className='qr-content-text'>{ev.venue}</span></p>
                                                        <p className='qr-content-text qr-content-date-time mb-2'>Registration Link: <a href={`/contact-us?event=${ev.eventtag}`} className='qr-content-text'>Click here</a></p>
                                                    </div>
                                                    <div className='col-3 p-0'>
                                                        {/* <img src={QR} className='img-fluid' alt="." /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div class="mt-0 mt-lg-4 align-items-center d-flex flex-column">
                                <Button type='button' className='button-title' onClick={() => setOpen(true)}>
                                    View All Events
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 row gx-5 mx-0">
                        <div class="col-12 col-lg-7 mt-2 p-0 pt-2 ps-0 rounded-3 d-flex flex-column justify-content-between">
                            <p className='title'>Our Associations</p>
                            <div className='container-fluid d-flex p-0 pe-lg-4 ps-0 justify-content-between'>
                                <div class="marketplace-card-container">
                                    {partenrs.map((p, i) => (
                                        <div className='card partner marketplace-card justify-content-center align-items-center p-2'>
                                            <div className=' d-flex justify-content-center align-items-center'>
                                                <img className='img-fluid' src={p} alt="ico" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div class="bg-white col-12 col-lg-5 mt-4 mt-lg-2 pt-2 px-3 rounded-4">
                            <p className='title'>Event Benefits</p>
                            <div className='container-fluid d-flex flex-column gap-2 mb-4 p-0'>
                                <div className='d-flex gap-2 align-items-center'>
                                    <img src={VERIFIED} alt="." />
                                    <p className='mb-0'>Check Your Global Readiness</p>
                                </div>
                                <div className='d-flex gap-2 align-items-center'>
                                    <img src={LAUNCH} alt="." />
                                    <p className='mb-0'>Get trained in launching your brand in the global markets </p>
                                </div>
                                <div className='d-flex gap-2 align-items-center'>
                                    <img src={GLOBAL} alt="." />
                                    <p className='mb-0'>Uncover Global Opportunities for Your Product</p>
                                </div>
                                <div className='d-flex gap-2 align-items-center'>
                                    <img src={TESLA} alt="." />
                                    <p className='mb-0'>Discover how VishwaSarthi  can empower your journey</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 row mx-0">
                        <div class="col-12 mt-2 p-0 pt-2 ps-0 rounded-3 d-flex flex-column justify-content-between">
                            <p className='title'>Our Marketplace Partners</p>
                            <div className='container-fluid d-flex p-0 justify-content-between'>
                                <div class="marketplace-card-container">
                                    {marketplacePartenrs.map((p, i) => (
                                        <div className='card marketplace-card justify-content-center align-items-center p-2'>
                                            <div className=' d-flex justify-content-center align-items-center'>
                                                <img className='img-fluid' src={p} alt="ico" />
                                            </div>
                                        </div>
                                    ))}
                                    <div className='card marketplace-card justify-content-center align-items-center p-2'>
                                        <div className=' d-flex justify-content-center align-items-center'>
                                            <p className='text-center mb-0'>
                                                +45 Others across Globe
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="m-0 mt-3 row gx-5">
                        <div class="col-12 col-lg-12 mt-2 px-0">
                            <p className='title'>Program Benefits</p>
                            <div className=''>
                                <div class="program-card-container">
                                    {icons.map((p, i) => (
                                        <div class="col program-card-parent">
                                            <div class="card program-card">
                                                <div className='program-icon-container'>
                                                    <img src={p.img} class="card-img-top" alt="ico" />
                                                </div>
                                                <div className='card-content'>
                                                    <p class="card-text px-2">{p.title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="m-0 mt-3 row gx-5">
                        <div class="col-12 col-lg-12 mt-2 px-0">
                            <p className='title'>Who Can Connect With Us</p>
                            <div className='row g-3'>
                                <div class="col-6 col-sm-4 col-lg-2 program-card-parent">
                                    <div class="card program-card program-card-1 rounded-3 p-2">
                                        <div className=''>
                                            <img src={Business} class="card-img-top" alt="ico" />
                                        </div>
                                        <div className='card-content mt-0'>
                                            <p class="mb-0 card-text px-2">MSME</p>
                                            <p class="mb-0 card-text-1 px-2">To launch, scale or effectively operate a global selling business</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-4 col-lg-2 program-card-parent">
                                    <div class="card program-card program-card-1 rounded-3 p-2">
                                        <div className=''>
                                            <img src={OnlineStore} class="card-img-top" alt="ico" />
                                        </div>
                                        <div className='card-content mt-0'>
                                            <p class="mb-0 card-text px-2">Marketplaces</p>
                                            <p class="mb-0 card-text-1 px-2">Collaborate to help support the MSMEs on your platform</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-4 col-lg-2 program-card-parent">
                                    <div class="card program-card program-card-1 rounded-3 p-2">
                                        <div className=''>
                                            <img src={EarthGlobe} class="card-img-top" alt="ico" />
                                        </div>
                                        <div className='card-content mt-0'>
                                            <p class="mb-0 card-text px-2">Others</p>
                                            <p class="mb-0 card-text-1 px-2">Discuss collaboration that helps enable MSMEs to go global.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-12 col-lg-6">
                                    <div className='w-100 h-100 gradient-card-1 p-1'>
                                        <div className='py-4 px-4 d-flex gap-2 flex-column align-items-center justify-content-center'>
                                            <img src={Letter} alt="." />
                                            <a href="mailto://vishwasarthi@goglocal.com">vishwasarthi@goglocal.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal size='lg' isOpen={open} className="custom-modal-class">
                <ModalBody>
                    <div class="modal-events-container">
                        {configData.map((ev, i) => (
                            <div key={i + '_' + ev.eventtag} className='event-card'>
                                <div className='event-info'>
                                    <div className='location'>
                                        <img src={LOC} alt="." />
                                        <p className='card-title'>{ev.location}</p>
                                    </div>
                                    <div className='qr-divider' />
                                    <div className='date-venue'>
                                        <p className='qr-content-text qr-content-date-time mb-2'>Date: <span className='qr-content-text'>{ev.date}</span></p>
                                        <p className='qr-content-text qr-content-date-time mb-2'>Venue: <span className='qr-content-text'>{ev.venue}</span></p>
                                        <p className='qr-content-text qr-content-date-time mb-2'>Registration Link: <a href={`/contact-us?event=${ev.eventtag}`} className='qr-content-text'>Click here</a></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggle} className={styles['custom-btn']}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
