import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalFooter, ModalBody, Spinner } from 'reactstrap';
import Papa from "papaparse";
import Header from './Header'
import OBJECTS from "../../assets/OBJECTS.svg";
import styles from "./styles.module.css";
import { useSearchParams } from 'react-router-dom';

const fields = [
    { id: "name", label: "Name", type: "text", value: "" },
    { id: "contact", label: "Contact No.", type: "tel", value: "", pattern: "[6789][0-9]{9}", title: "Please enter valid phone number" },
    { id: "email", label: "Email ID (personal)", type: "email", value: "", title: "Please enter valid email" },
    { id: "companyName", label: "Company’s name", type: "text", value: "" },
];

export default function ContactForm() {
    const [formData, setFormData] = useState(fields);
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [configData, setConfigData] = useState([]);
    const [eventData, setEventData] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vREHrl7_C1l7WqDVZzWVGleOHC5siS_XSzMLrJpIAVkqLKx1UM3dYAhd4dVIaH0A1cVi3gPVmag4Pmw/pub?output=csv", {
            download: true,
            header: true,
            complete: (results) => {
                setConfigData(results.data);
                if (searchParams.get('event')) {
                    const eventDetails = results.data.find(item => item.eventtag === searchParams.get('event'));
                    if (eventDetails) {
                        setEventData(eventDetails);
                    }
                }
            }
        });
        Array.from(configData);
    }, [searchParams]);

    const toggle = () => setOpen(!open);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
        if (!emailRegex.test(formData[2].value)) {
            setOpen(true);
            setSuccess(false);
            setMessage('Please enter a valid email address!');
            return;
        }
        setShowLoader(true);

        const formSpreeData = {};
        formSpreeData['location'] = searchParams.get('event') && eventData && eventData.location ? eventData.location : '';
        formSpreeData['eventTag'] = searchParams.get('event') && eventData && eventData.eventtag ? eventData.eventtag : '';
        formSpreeData['venue'] = searchParams.get('event') && eventData && eventData.venue ? eventData.venue : '';
        formSpreeData['date'] = searchParams.get('event') && eventData && eventData.date ? eventData.date : '';

        formData.forEach((field) => {
            formSpreeData[field.id] = field.value;
        });

        const formSpreeEndpoint = 'https://formspree.io/f/moqooqke';

        try {
            const response = await fetch(formSpreeEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formSpreeData),
            });

            if (response && response.ok) {
                setFormData(fields.map((field) => ({ ...field, value: '' })));
                setMessage('Your data has been submitted successfully!');
                setSuccess(true);
            } else {
                setMessage('Something went wrong, Please try again!');
                setSuccess(false);
            }
            setOpen(true);
            setShowLoader(false);
        } catch (error) {
            setMessage('Something went wrong, Please try again!');
            setSuccess(false);
            setOpen(true);
            setShowLoader(false);
        }
    }

    const handleChange = (e) => {
        const ind = formData.findIndex(field => field.id === e.target.name);
        if (ind >= 0);
        setFormData((p) => Object.assign([...p], { [ind]: { ...p[ind], value: e.target.value } }));
    };

    return (
        <>
            <Header />
            <img className={styles['right-corner-image']} src={OBJECTS} alt="." />
            <div className={`container d-flex flex-column ${styles["main-section"]} gap-3`} >
                <div>
                    <h1 className={styles['title']}>Welcome</h1>
                    <h6 className={styles['sub-title']}>Let's take you Global!</h6>
                </div>
                {eventData && eventData.location && eventData.venue && eventData.date && (
                    <div className={`${styles['addr-card']} p-2`}>
                        <div className='d-flex flex-row justify-content-between mb-2'>
                            <span>Event Location:</span>
                            <span className='text-end'>{eventData.location}</span>
                        </div>
                        <div className='d-flex flex-row justify-content-between mb-2'>
                            <span>Event Venue:</span>
                            <span className='text-end'>{eventData.venue}</span>
                        </div>
                        <div className='d-flex flex-row justify-content-between mb-2'>
                            <span>Event Date:</span>
                            <span className='text-end'>{eventData.date}</span>
                        </div>
                        <div className='d-flex flex-row justify-content-between '>
                            <span>Address:</span>
                            <span className='text-end'>{eventData.address}</span>
                        </div>
                    </div>
                )}
                <form action="#" onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e)
                }} className='d-flex flex-column gap-3'>
                    {formData.map(field => {
                        return (
                            <input
                                key={field.id}
                                name={field.id}
                                required
                                placeholder={field.label}
                                onChange={handleChange}
                                className={styles['custom-input']}
                                {...field}
                            />
                        )
                    })}
                    <div className='d-flex justify-content-end mt-3'>
                        <Button
                            disabled={formData.some(a => !a.value) || showLoader}
                            type='submit' className={styles['custom-btn']}>
                            {showLoader ? <Spinner color="light" size="sm" /> : 'Submit'}
                        </Button>
                    </div>
                </form>
            </div>

            <Modal isOpen={open}>
                <ModalBody>
                    <div className={success ? 'text-success' : 'text-danger'}>
                        {message}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className={styles['custom-btn']} onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}
