import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./Pages/Home";
// import Events from "./Pages/Events";
import FAQs from "./Pages/FAQs";
import Layout from "./Layout";
import ContactForm from "./Pages/ContactForm";


const Dashboard = () => {
  return (
    <Layout>
      <Home />
      {/* <Events /> */}
      <FAQs />
    </Layout>
  )
}

function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/contact-us" element={<ContactForm />} />
          <Route path="*" element={<Dashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
