import React, { useEffect, useState } from 'react';
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
} from 'reactstrap';
import Logo from "../assets/Logo.png";
import "./styles.css";
import Vector3 from "../assets/Vector (3).svg";
import Vector4 from "../assets/Vector (4).svg";
import Vector5 from "../assets/Vector (5).svg";
import Internet from "../assets/Group.svg";
import scrollSpy from "simple-scrollspy";
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import EmailFieldForm from '../Components/EmailFieldForm';

export default function Layout(props) {
    const [sticky, setSticky] = useState("");
    const [activeClass, setActiveClass] = useState("home");
    const [initial, setInitial] = useState(false);
    const url = 'https://alchemytech.us21.list-manage.com/subscribe/post?u=e9afd46021c59c7cc510d8f29&id=f381e035da&f_id=008ae8e6f0';

    useEffect(() => {
        window.onload = function () {
            scrollSpy('#menu-links', {
                offset: 100, // in pixels
                menuActiveTarget: 'a',
                sectionClass: '.scrollspy',
                activeClass: 'active',
                scrollContainer: '',
                onActive
            })
        }
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    const onActive = (test) => {
        setActiveClass(test.href)
    }

    const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 20 ? "is-sticky" : "";
        setSticky(stickyClass);
    };

    const classes = `header-section ${sticky}`;
    const applyInactiveClass = (path) => !activeClass?.includes("home") && !activeClass?.includes(path);

    return (
        <>
            <Navbar className={classes}>
                <div id="menu-links" className='container nav-content d-flex justify-content-between w-100'>
                    <NavbarBrand href="#home" data-scrollspy="#home"><img className='brand-logo' src={Logo} alt="goGLocal" width={163} height={32} /></NavbarBrand>
                    <Nav className="d-flex flex-row ml-auto justify-content-center align-items-center" navbar>
                        <NavItem>
                            <a href="#faqs" data-scrollspy="#faqs" className={`link ${applyInactiveClass("#faqs") && "active-link"}`}>
                                FAQs
                            </a>
                        </NavItem>
                        <NavItem>
                            <a href="/contact-us" data-scrollspy="#contact-us" className={`link ${applyInactiveClass("#contact-us") && "active-link"}`}>
                                Contact Us
                            </a>
                        </NavItem>
                    </Nav>
                </div>
            </Navbar>
            <div className='container d-flex flex-column pt-2' >
                <div className='container-fluid root flex-1 '>
                    {props.children}
                </div>
            </div>
            <div className='bg-dark mt-5 pt-5'>
                <div class="container pb-3">
                    <div class="row gx-3">
                        <div class="col-12 col-md-7 mb-5 mb-md-0">
                            <p className='footer-text'>Get Started for FREE</p>
                            <MailchimpSubscribe
                                url={url}
                                render={({ subscribe, status, message }) => (
                                    <EmailFieldForm initial={initial} setInitial={setInitial} onSubmit={subscribe} status={status} message={message} />
                                )} />

                        </div>
                        <div class="col-12 col-md-5">
                            <p className='footer-text'>Connect with us</p>
                            <div>
                                <a href="https://www.linkedin.com/company/82762671/admin/feed/posts/" className='social-icon'>
                                    <img src={Vector3} alt="linkdln" />
                                </a>
                                <a href="https://www.instagram.com/goglocal.live/" className='mx-4 social-icon'>
                                    <img src={Vector4} alt="insta" />
                                </a>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='row mt-5 d-flex'>
                                <div className='col-12 col-sm-7'>
                                    <div className='row'>
                                        <div className='col-12 col-lg-3 d-flex align-items-center p-sm-0'>
                                            <p className='footer-bottom-text mr-4 mb-3 mb-lg-0'>Privacy Policy</p>
                                        </div>
                                        <div className='col-12 col-lg-6 d-flex align-items-center p-sm-0'>
                                            <p className='footer-bottom-text mb-3 mb-lg-0'>Terms and Conditions</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-5'>
                                    <div className='row'>
                                        <div className="col-12 col-lg-7 d-flex align-items-center p-sm-0 mb-3 mb-lg-0">
                                            <img src={Vector5} alt="linkdln" />
                                            <a className='footer-bottom-text mx-2' href='mailto://contactus@goglocal.com'>contactus@goglocal.com  </a>
                                        </div>
                                        <div className='col-12 col-lg-5 d-flex align-items-center p-sm-0 mb-3 mb-lg-0'>
                                            <img src={Internet} alt="linkdln" />
                                            <a className='footer-bottom-text mx-2' href="https://www.goglocal.live">www.goglocal.live</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
